<template>
	<div class="row-renting">
		<div class="grid row-renting-content">
			<div v-for="item of content" :key="item.icon" class="col-12 md:col-4">
				<div class="row-renting-header">
					<i :class="'pi pi-' + item.icon" />
				</div>
				<div class="row-renting-body">
					<p>{{ item.text }}</p>
				</div>
				<div class="row-renting-footer"></div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'row-renting',
	data() {
		return {
			content: [],
		};
	},
	computed: {},
	methods: {},
	components: {},
	created() {},
	mounted() {
		this.content = [];
	},
	watch: {},
};
</script>

<style>
.row-renting {
	margin: 20px;
	padding: 20px;
	background-color: #b1cd3f;
}

.row-renting-content > div {
	padding: 20px 20px 0;
	text-align: center;
    font-family: Poppins, 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.row-renting-content .row-renting-header {
	font-size: 3rem;
	font-weight: 700;
	color: #ffffff;
}

.row-renting-content .row-renting-header .pi {
	font-size: 4rem;
}

.row-renting-content .row-renting-body {
	margin: 20px;
	font-size: 1.2rem;
	font-weight: 500;
}
</style>