import { axiosApi } from '@/axios.js';

const headers = { "Content-Type": "application/json", "Accept": "application/json" };

export default class PublicService {

	getPages() {
		// return all slugs available
		return axiosApi.get('/pages', { headers })
			.then(res => {
				return {
					'status': {
						'code': res.status,
						'message': res.statusText
					},
					'pages': res.data.pages ?? [],
					'error': res.data.error,
					'message': res.data.message ?? ''
				};
			})
			.catch(error => {
				return this.handleError(error);
			});
	}

	getPage(slug) {
		return axiosApi.get('/page/' + slug, { headers })
			.then(res => {
				return {
					'status': {
						'code': res.status,
						'message': res.statusText
					},
					'page': res.data.content,
					'error': res.data.error,
					'message': res.data.message ?? ''
				};
			})
			.catch(error => {
				return this.handleError(error);
			});
	}

	/* Other */

	handleError(error) {
		return {
			'status': {
				'code': error.response.status,
				'message': error.response.statusText
			},
			'error': error.response.status,
			'message': error.response.statusText,
			'response': error.response.data,
		};
	}
}