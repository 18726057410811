<template>
	<div id="footer">
		<div class="grid">
			<div class="col-12 md:col-9 lg:col-6 flex-order-3 md:flex-order-1">
				<ul class="contact-menu" style="color: #ffffff">
					<div class="grid">
						<div class="col-12 md:col-6">
							<li class="footer-header">
								<a href="/#/p/contact" @click="openPageContact">{{ $t('LANDING.FOOTER.CONTACT.TITLE') }}</a>
							</li>
							<li>
								<i class="pi pi-phone"></i>
								<a :href="'tel:' + $t('LANDING.FOOTER.CONTACT.PHONE')">{{ $t('LANDING.FOOTER.CONTACT.PHONE') }}</a>
							</li>
							<li>
								<i class="pi pi-envelope"></i>
								<a :href="'mailto:' + $t('LANDING.FOOTER.CONTACT.EMAIL')">{{ $t('LANDING.FOOTER.CONTACT.EMAIL') }}</a>
							</li>
							<li>
								<i class="pi pi-map-marker"></i>
								<div style="display: inline-block; vertical-align: top">{{ $t('LANDING.FOOTER.CONTACT.ADDRESS') }}</div>
							</li>
						</div>
						<div class="col-12 md:col-6">
							<li class="footer-header">{{ $t('LANDING.FOOTER.SOCIALS.TITLE') }}</li>
							<li>
								<i class="pi pi-twitter"></i>
								<a href="https://twitter.com/SmsYou_france">{{ $t('LANDING.FOOTER.SOCIALS.TWITTER') }}</a>
							</li>
							<li>
								<i class="pi pi-linkedin"></i>
								<a href="https://www.linkedin.com/company/smsyou/">{{ $t('LANDING.FOOTER.SOCIALS.LINKEDIN') }}</a>
							</li>
							<li>
								<i class="pi pi-youtube"></i>
								<a href="https://www.youtube.com/channel/UCOw7AgmayXFrtF5hn4h5K2Q">{{ $t('LANDING.FOOTER.SOCIALS.YOUTUBE') }}</a>
							</li>
						</div>
					</div>
				</ul>
			</div>
			<div class="col-0 lg:col-3 flex-order-2 md:flex-order-2"></div>
			<div class="col-12 md:col-3 flex-order-1 md:flex-order-3">
				<ul class="site-menu">
					<li class="footer-header">
						<a href="/#">{{ $t('LANDING.MENU.HOME') }}</a>
					</li>
					<li>
						<a href="/#/p/sms" @click="openPageSms">{{ $t('LANDING.MENU.SMS') }}</a>
					</li>
					<li>
						<a href="/#/p/tarifs" @click="openPagePricing">{{ $t('LANDING.MENU.PRICING') }}</a>
					</li>
					<!-- <li>
							<a href="/#/p/location-bdd" @click="openPageRenting">{{ $t('LANDING.MENU.RENTING') }}</a>
						</li> -->
					<li>
						<a href="/#/p/ressources" @click="openPageResources">{{ $t('LANDING.MENU.RESOURCES') }}</a>
					</li>
					<li>
						<a href="/#/p/a-propos" @click="openPageWhoswho">{{ $t('LANDING.MENU.WHOSWHO') }}</a>
					</li>
					<li>
						<a href="/#/p/cgu" @click="openPageCgu">{{ $t('LANDING.MENU.TERMSOFSERVICE') }}</a>
					</li>
					<li>
						<a href="/#/p/mentions-legales" @click="openPageLegal">{{ $t('LANDING.MENU.LEGALNOTICE') }}</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'public-footer',
	props: {},
	data() {
		return {};
	},
	computed: {},
	methods: {
		openPageSms() {
			document.location = '#/p/sms';
			document.location.reload();
		},
		openPagePricing() {
			document.location = '#/p/tarifs';
			document.location.reload();
		},
		openPageRenting() {
			document.location = '#/p/location-bdd';
			document.location.reload();
		},
		openPageResources() {
			document.location = '#/p/ressources';
			document.location.reload();
		},
		openPageContact() {
			document.location = '#/p/contact';
			document.location.reload();
		},
		openPageCgu() {
			document.location = '#/p/cgu';
			document.location.reload();
		},
		openPageLegal() {
			document.location = '#/p/mentions-legales';
			document.location.reload();
		},
		openPageWhoswho() {
			document.location = '#/p/a-propos';
			document.location.reload();
		},
	},
	components: {},
	created() {},
	mounted() {},
	watch: {},
};
</script>

<style>
</style>