<template>
	<div class="row-whoswho">
		<div class="grid row-whoswho-content">
			<div v-for="item of content" :key="item.icon" class="col-12 sm:col-6 lg:col-4">
				<div class="row-whoswho-header">
					<i :class="'pi pi-' + item.icon" />
				</div>
				<div class="row-whoswho-body">
					<h3>{{ item.title }}</h3>
					<p>{{ item.text }}</p>
				</div>
				<div class="row-whoswho-footer"></div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'row-whoswho',
	data() {
		return {
			content: [],
		};
	},
	computed: {},
	methods: {},
	components: {},
	created() {},
	mounted() {
		this.content = [
			{
				icon: 'home',
				text: this.$t('PAGE.ROW.WHOSWHO.VAL01.TEXT'),
				title: this.$t('PAGE.ROW.WHOSWHO.VAL01.TITLE'),
			},
			{
				icon: 'eye',
				text: this.$t('PAGE.ROW.WHOSWHO.VAL02.TEXT'),
				title: this.$t('PAGE.ROW.WHOSWHO.VAL02.TITLE'),
			},
			{
				icon: 'mobile',
				text: this.$t('PAGE.ROW.WHOSWHO.VAL03.TEXT'),
				title: this.$t('PAGE.ROW.WHOSWHO.VAL03.TITLE'),
			},
		];
	},
	watch: {},
};
</script>

<style>
.row-whoswho {
	margin: 20px;
	padding: 20px;
	background-color: #fad300;
}

.row-whoswho-content > div {
	padding: 20px 20px 0;
	text-align: center;
	font-family: Poppins, 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.row-whoswho-content .row-whoswho-header {
	font-size: 3rem;
	font-weight: 700;
	color: #ffffff;
}

.row-whoswho-content .row-whoswho-header .pi {
	font-size: 4rem;
}

.row-whoswho-content .row-whoswho-header span {
	display: inline-block;
	vertical-align: top;
}

.row-whoswho-content .row-whoswho-body {
	margin: 20px;
	font-size: 1.2rem;
	font-weight: 500;
}
</style>