import axios from 'axios';
import router from '@/router'

const syApi = "https://api.smsyou.fr";
const pwApi = "https://powow4.iroquois.fr/api.php";
// const syApi = "https://api.preprod.smsyou.fr";
// const syApi = "http://api.smsyoudev";
// const pwApi = "https://powow4-preprod.iroquois.fr/api.php";
const nmApi = "https://nominatim.openstreetmap.org";

function headers() {
	let token = JSON.parse(localStorage.getItem('token'));
	let hdrs = {
		'Access-Control-Allow-Origin': '*',
		'Referer-Policy': 'no-referrer-when-downgrade',
		'Accept': 'application/json',
		"Content-Type": "application/json",
		"X-User-Info": "true",
	};
	if (token && token != '') {
		hdrs.Authorization = 'Bearer ' + token;
	}
	return hdrs;
}

const axiosApi = axios.create({
	baseURL: syApi,
	headers: headers()
});

axiosApi.interceptors.request.use(
    config => {
        let token = JSON.parse(localStorage.getItem('token'));
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    error => {
        Promise.reject(error)
    });

axiosApi.interceptors.response.use( response => {
        return response
      }, error => {if (error.response.status === 401) {
        router.push('/connexion')
      }
        return Promise.reject(error)
      })

const powowRest = axios.create({
	baseURL: pwApi,
	headers: {
		"Content-Type": "application/x-www-form-urlencoded",
		'Access-Control-Allow-Origin': '*',
	}
});

const nominatimApi = axios.create({
	baseURL: nmApi,
	headers: {
		"Content-Type": "application/x-www-form-urlencoded",
		'Access-Control-Allow-Origin': '*',
	}
});

const getPowowApiKey = () => {return JSON.parse(localStorage.getItem('pwkey'))};

export { axiosApi, powowRest, getPowowApiKey, nominatimApi };