<template>
	<div class="row-resources">
		<DataView :value="pages" layout="grid" paginatorPosition="bottom" :paginator="true" :rows="9" :dataKey="sortKey" :sortField="sortField" :sortOrder="sortOrder" class="grid row-resources-content">
			<template #grid="slotProps">
				<div class="col-12 sm:col-6 md:col-4">
					<div class="row-resources-header"></div>
					<div class="row-resources-body">
						<i v-if="slotProps.data.type == 'blog'" class="pi pi-book" />
						<i v-else-if="slotProps.data.type == 'tuto'" class="pi pi-paperclip" />
						<i v-else class="pi pi-box" />
						<a :href="'/#/p/' + slotProps.data.slug" @click="openPage('/#/p/' + slotProps.data.slug)">{{ slotProps.data.title }}</a>
					</div>
				</div>
				<div class="row-resources-footer"></div>
			</template>
		</DataView>
	</div>
</template>

<script>
export default {
	name: 'row-resources',
	props: {
		pages: {
			type: Array,
			required: false,
			default: () => {
				return [];
			},
		},
	},
	data() {
		return {
			sortOrder: null,
			sortField: null,
			sortKey: null,
		};
	},
	computed: {},
	methods: {
		openPage(url) {
			location = url;
			location.reload();
		},
	},
	components: {},
	created() {},
	mounted() {
		this.sortOrder = -1;
		this.sortField = 'date';
		this.sortKey = 'slug';
	},
	watch: {},
};
</script>

<style>
.row-resources {
	margin: 20px;
	padding: 20px;
	background-color: #2c9cec;
}

.row-resources-content > div {
	padding: 20px 20px 0;
	text-align: center;
	font-family: Poppins, 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.row-resources-content .row-resources-body {
	margin: 20px;
	text-align: left;
}

.row-resources-content .row-resources-body a {
	font-size: 1.2rem;
	font-weight: 500;
	text-decoration: underline;
	color: #3e3e3e;
}

.row-resources-content .row-resources-body:hover a {
	color: #ffffff;
}

.row-resources-content .row-resources-body .pi {
	margin-right: 1rem;
	font-size: 1.3rem;
	color: #ffffff;
}

.row-resources-content.p-dataview .p-dataview-content {
	background-color: transparent !important;
}

.row-resources-content.p-dataview .p-paginator.p-component.p-paginator-bottom {
	width: inherit;
	background-color: transparent;
	border: none;
}

.row-resources-content.p-dataview .p-paginator .p-paginator-first,
.row-resources-content.p-dataview .p-paginator .p-paginator-prev,
.row-resources-content.p-dataview .p-paginator .p-paginator-next,
.row-resources-content.p-dataview .p-paginator .p-paginator-last {
	background-color: transparent;
	border: none;
	color: #ffffff;
}

.row-resources-content.p-dataview .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
	background-color: #ffffff;
	color: #2c9cec;
	font-weight: 700;
	border-radius: 100%;
	border-color: #ffffff;
}

.row-resources-content.p-dataview .p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight) {
	background-color: transparent;
	border: none;
	color: #ffffff;
}
</style>