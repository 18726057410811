<template>
	<div class="row-contact">
		<div class="grid row-contact-content">
			<div class="col-12 md:col-4">
				<div class="row-contact-header">
					<i class="pi pi-phone" />
				</div>
				<div class="row-contact-body">
					<h3>{{ $t('PAGE.ROW.CONTACT.PHONE.TITLE') }}</h3>
					<a :href="'tel:' + $t('PAGE.ROW.CONTACT.PHONE.TEXT')">{{ $t('PAGE.ROW.CONTACT.PHONE.TEXT') }}</a>
				</div>
				<div class="row-contact-footer"></div>
			</div>
			<div class="col-12 md:col-4">
				<div class="row-contact-header">
					<i class="pi pi-at" />
				</div>
				<div class="row-contact-body">
					<h3>{{ $t('PAGE.ROW.CONTACT.EMAIL.TITLE') }}</h3>
					<a :href="'mailto:' + $t('PAGE.ROW.CONTACT.EMAIL.TEXT')">{{ $t('PAGE.ROW.CONTACT.EMAIL.TEXT') }}</a>
				</div>
				<div class="row-contact-footer"></div>
			</div>
			<div class="col-12 md:col-4">
				<div class="row-contact-header">
					<i class="pi pi-envelope" />
				</div>
				<div class="row-contact-body">
					<h3>{{ $t('PAGE.ROW.CONTACT.ADDRESS.TITLE') }}</h3>
					<p>{{ $t('PAGE.ROW.CONTACT.ADDRESS.TEXT') }}</p>
				</div>
				<div class="row-contact-footer"></div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'row-contact',
	data() {
		return {
			content: [],
		};
	},
	computed: {},
	methods: {},
	components: {},
	created() {},
	mounted() {
		this.content = [
			{
				icon: 'phone',
				text: this.$t('PAGE.ROW.CONTACT.PHONE.TEXT'),
				title: this.$t('PAGE.ROW.CONTACT.PHONE.TITLE')
			},
			{
				icon: 'at',
				text: this.$t('PAGE.ROW.CONTACT.EMAIL.TEXT'),
				title: this.$t('PAGE.ROW.CONTACT.EMAIL.TITLE')
			},
			{
				icon: 'envelope',
				text: this.$t('PAGE.ROW.CONTACT.ADDRESS.TEXT'),
				title: this.$t('PAGE.ROW.CONTACT.ADDRESS.TITLE')
			},
		];
	},
	watch: {},
};
</script>

<style>
.row-contact {
	margin: 20px;
	padding: 20px;
	background-color: #b1cd3f;
}

.row-contact-content > div {
	padding: 20px 20px 0;
	text-align: center;
    font-family: Poppins, 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.row-contact-content .row-contact-header {
	font-size: 3rem;
	font-weight: 700;
	color: #ffffff;
}

.row-contact-content .row-contact-header .pi {
	font-size: 4rem;
}

.row-contact-content .row-contact-body {
	margin: 0 20px 20px;
}

.row-contact-content .row-contact-body p,
.row-contact-content .row-contact-body a {
	font-size: 1.5rem;
	font-weight: 500;
	white-space: pre-line;
	color: #000000;
}

.row-contact .row-contact-content .row-contact-body h3 {
    font-family: Fredoka, 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.8rem;
    text-align: center;
}
</style>