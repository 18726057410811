<template>
	<div>
		<div class="landing-body">
			<div class="landing-wrapper">
				<div id="header">
					<Menu :propSlug="pageCategory" />
					<div class="header-content">
						<h1>{{ pageTitle }}</h1>
						<!-- <h2 style="line-height: 1.5">{{ $t('LANDING.HEADER.LINE_1') }}</h2> -->
						<!-- <div>
							<button class="section-button" @click="openPageSms">{{ $t('BUTTON.MORE') }}</button>
						</div> -->
						<!-- <div class="motive">
							<p>{{ $t('LANDING.HEADER.LINE_2') }}</p>
							<p>{{ $t('LANDING.HEADER.LINE_3') }}</p>
						</div> -->
					</div>
				</div>

				<div id="main-section" :class="pageCategory" v-html="pageBody"></div>

				<!-- Existing slugs : conditions | nous-contacter | sms-marketing | tarifs | ressources | location-bdd | mentions-legales -->

				<SmsRow v-if="pageCategory == 'sms'" id="row-sms" />

				<PricingRow v-if="pageCategory == 'tarifs'" id="row-pricing" />

				<RentingRow v-if="pageCategory == 'location-bdd'" id="row-renting" />

				<ResourcesRow v-if="pageCategory == 'ressources' && blogPages.length >= 1" :pages="blogPages" id="row-resources" />

				<ContactRow v-if="pageCategory == 'contact'" id="row-contact" />

				<WhoswhoRow v-if="pageCategory == 'a-propos'" id="row-whoswho" />

				<div v-if="pageCategory == 'conditions'" id="row-cgu"></div>
				<div v-if="pageCategory == 'mentions-legales'" id="row-legal"></div>

				<!-- <div id="final-section" class="pageCategory" v-html="pageBody"></div> -->

				<Footer />
			</div>
		</div>
	</div>
</template>

<script>
import ContactRow from '@/components/public/ContactRow.vue';
import Footer from '@/components/public/Footer.vue';
import Menu from '@/components/public/Menu.vue';
import PricingRow from '@/components/public/PricingRow.vue';
import RentingRow from '@/components/public/RentingRow.vue';
import ResourcesRow from '@/components/public/ResourcesRow.vue';
import SmsRow from '@/components/public/SmsRow.vue';
import WhoswhoRow from '@/components/public/WhoswhoRow.vue';
import PublicService from '@/service/PublicService.js';

export default {
	name: 'public-page',
	publicService: null,
	data() {
		return {
			pageBody: '',
			pageCategory: '',
			pageSlug: '',
			pageTitle: '',
			publicPages: [],
			blogPages: [],
		};
	},
	computed: {},
	methods: {
		redirect404() {
			document.location = '#/notfound';
			location.reload();
			console.log('Erreur : page introuvable');
		},
	},
	components: {
		ContactRow,
		Footer,
		Menu,
		PricingRow,
		RentingRow,
		ResourcesRow,
		SmsRow,
		WhoswhoRow,
	},
	created() {
		this.publicService = new PublicService();
	},
	mounted() {
		this.publicPages = {
			sms: 'sms-marketing',
			tarifs: 'tarifs-sms',
			'location-bdd': 'location-bdd',
			ressources: 'ressources',
			cgu: 'conditions',
			'mentions-legales': 'mentions-legales',
			contact: 'nous-contacter',
			'a-propos': 'qui-sommes-nous',
		};

		this.originalSlug = this.$route.params.slug;
		if (this.$route.params.pre) {
			this.originalSlug = this.$route.params.pre + '-' + this.originalSlug;
			this.pageCategory = this.$route.params.pre;
		} else {
			this.pageCategory = this.$route.params.slug;
		}

		this.publicService
			.getPages()
			.then((res) => {
				let blogRegex = /^(tuto-|blog-|ressources-)/;
				for (let page of res.pages) {
					if (blogRegex.test(page.page)) {
						let newPage = {
							title: page.title,
							date: page.modified,
						};
						let type = /^(tuto|blog|ressources)/.exec(page.page)[0];
						newPage.slug = page.page.replace(type + '-', type + '/');
						newPage.type = type;
						this.blogPages.push(newPage);
					}
					this.publicPages[page.page] = page.page;
				}

				if (!this.publicPages[this.originalSlug]) {
					this.redirect404();
				}

				this.publicService
					.getPage(this.publicPages[this.originalSlug])
					.then((res) => {
						if (res.status.code == 200) {
							this.pageTitle = res.page.title;
							this.pageBody = res.page.body;
							this.pageSlug = res.page.page;
						} else {
							console.log('Error: ' + res.status.message);
							this.redirect404();
						}
					})
					.catch((error) => {
						console.log('Error: ' + error);
						this.redirect404();
					});
			})
			.catch((error) => {
				console.log('Error: ' + error);
				this.redirect404();
			});
	},
	watch: {},
};
</script>

<style lang="scss" scoped>
a {
	color: #000000;
	text-decoration: none;
}
</style>