<template>
	<div class="header-top">
		<div class="logo">
			<a href="/">
				<img src="../../assets/layout/images/landing/logo-landing.png" />
				<p class="brand">{{ $t('APP.NAME') }}</p>
			</a>
		</div>

		<ul class="landing-menu">
			<li>
				<a href="#/p/sms" :class="propSlug == 'sms' ? 'current' : ''" @click="openPageSms">{{ $t('LANDING.MENU.SMS') }}</a>
			</li>
			<li>
				<a href="#/p/tarifs" :class="propSlug == 'tarifs' ? 'current' : ''" @click="openPagePricing">{{ $t('LANDING.MENU.PRICING') }}</a>
			</li>
			<!-- <li>
				<a href="#/p/location-bdd" :class="propSlug == 'location-bdd' ? 'current' : ''" @click="openPageRenting">{{ $t('LANDING.MENU.RENTING') }}</a>
			</li> -->
			<li>
				<a href="#/p/ressources" :class="propSlug == 'ressources' || propSlug == 'blog' || propSlug == 'tuto' ? 'current' : ''" @click="openPageResources">{{ $t('LANDING.MENU.RESOURCES') }}</a>
			</li>
		</ul>

		<div class="sign-up-buttonpane">
			<button v-if="!connected" @click="$router.push({ name: 'user-login' })">{{ $t('BUTTON.LOGIN') }}</button>
			<button v-if="!connected" @click="$router.push({ name: 'user-sign-in' })">{{ $t('BUTTON.REGISTER') }}</button>
			<button v-if="connected" @click="$router.push({ name: 'user-dashboard' })">{{ $t('BUTTON.PLATFORM') }}</button>
		</div>
	</div>
</template>

<script>
import UserService from '@/service/UserService';
export default {
	name: 'public-menu',
	userService: null,
	props: {
		propSlug: {
			type: String,
			required: false,
			default: () => {
				return 'home';
			},
		},
	},
	data() {
		return {
			connected: false,
		};
	},
	computed: {},
	methods: {
		openPageSms() {
			document.location = '#/p/sms';
			document.location.reload();
		},
		openPagePricing() {
			document.location = '#/p/tarifs';
			document.location.reload();
		},
		openPageRenting() {
			document.location = '#/p/location-bdd';
			document.location.reload();
		},
		openPageResources() {
			document.location = '#/p/ressources';
			document.location.reload();
		},
	},
	components: {},
	created() {
		this.userService = new UserService();
	},
	mounted() {
		let token = JSON.parse(localStorage.getItem('token'));
		if (token) {
			this.connected = true;
		}
	},
	watch: {},
};
</script>

<style>
a.current {
	text-decoration-line: underline;
	text-decoration-style: solid;
	text-decoration-color: #faa633;
	text-decoration-thickness: 5px;
}
</style>