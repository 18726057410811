<template>
	<div class="topbar clearfix">
		<div class="topbar-left">
			<router-link :to="{ name: 'user-dashboard' }">
				<button class="p-link">
					<img alt="Logo" src="layout/images/logo_h_light.png" class="topbar-logo" />
				</button>
			</router-link>
		</div>

		<div class="topbar-right">
			<button class="p-link" id="topbar-menu-button" @click="onTopbarMenuButtonClick">
				<i class="pi pi-bars"></i>
			</button>
			<ul :class="topbarItemsClass">
				<li :class="['profile-item', { 'active-top-menu': activeTopbarItem === 'profile' }]" @click="$emit('topbar-item-click', { originalEvent: $event, item: 'profile' })">
					<button class="p-link">
						<img alt="User" class="profile-image" src="layout/images/avatar.png" />
						<div>
							<span class="topbar-item-name">{{ user.firstname }} {{ user.lastname }}</span>
							<span class="topbar-item-role">{{ user.company }}</span>
						</div>
					</button>

					<transition name="layout-submenu-container">
						<ul class="layout-menu fadeInDown" v-show="activeTopbarItem === 'profile'">
							<router-link :to="{ name: 'user-profile' }">
								<li role="menuitem">
									<button class="p-link topbar-item-button" v-tooltip.bottom="$t('MENU.PROFILE')">
										<i class="pi pi-fw pi-user"></i>
										<span>{{ $t('MENU.PROFILE') }}</span>
									</button>
								</li>
							</router-link>
							<router-link :to="{ name: 'invoices' }">
								<li role="menuitem">
									<button class="p-link topbar-item-button" v-tooltip.bottom="$t('MENU.INVOICES')">
										<i class="pi pi-fw pi-file-pdf"></i>
										<span>{{ $t('MENU.INVOICES') }}</span>
									</button>
								</li>
							</router-link>
							<!-- <li role="menuitem">
								<button class="p-link topbar-item-button" v-tooltip.bottom="$t('MENU.SETTINGS')">
									<i class="pi pi-fw pi-cog"></i>
									<span>{{ $t('MENU.SETTINGS') }}</span>
								</button>
							</li> -->
							<li role="menuitem">
								<button class="p-link topbar-item-button" v-tooltip.bottom="$t('MENU.DISCONNECT')" @click="logout">
									<i class="pi pi-fw pi-sign-out"></i>
									<span>{{ $t('MENU.DISCONNECT') }}</span>
								</button>
							</li>
						</ul>
					</transition>
				</li>

				<li :class="[{ 'active-top-menu': activeTopbarItem === 'list-rent' }, 'topbar-item']" @click="$emit('topbar-item-click', { originalEvent: $event, item: 'list-rent' })">
					<router-link :to="{ name: 'list-rent' }">
						<button class="p-link" v-tooltip.bottom="$t('MENU.RENTAL')">
							<i class="topbar-icon pi pi-fw pi-shopping-cart"></i>
							<span class="topbar-item-name">{{ $t('MENU.RENTAL') }}</span>
						</button>
					</router-link>
				</li>
				<li :class="[{ 'active-top-menu': activeTopbarItem === 'campaigns' }, 'topbar-item']" @click="$emit('topbar-item-click', { originalEvent: $event, item: 'campaigns' })">
					<router-link :to="{ name: 'campaigns' }">
						<button class="p-link" v-tooltip.bottom="$t('MENU.CAMPAIGNS')">
							<i class="topbar-icon pi pi-fw pi-mobile"></i>
							<span class="topbar-item-name">{{ $t('MENU.CAMPAIGNS') }}</span>
						</button>
					</router-link>
				</li>
				<li :class="[{ 'active-top-menu': activeTopbarItem === 'lists' }, 'topbar-item']" @click="$emit('topbar-item-click', { originalEvent: $event, item: 'lists' })">
					<router-link :to="{ name: 'lists' }">
						<button class="p-link" v-tooltip.bottom="$t('MENU.LISTS')">
							<i class="topbar-icon pi pi-fw pi-users"></i>
							<span class="topbar-item-name">{{ $t('MENU.LISTS') }}</span>
						</button>
					</router-link>
				</li>
				<li v-if="user.type == 'Admin'" :class="[{ 'active-top-menu': activeTopbarItem === 'admin-dashboard' }, 'topbar-item']" @click="$emit('topbar-item-click', { originalEvent: $event, item: 'admin-dashboard' })">
					<router-link :to="{ name: 'admin-dashboard' }">
						<button class="p-link" v-tooltip.bottom="$t('MENU.ADMIN')">
							<i class="topbar-icon pi pi-fw pi-star-fill"></i>
							<span class="topbar-item-name">{{ $t('MENU.ADMIN') }}</span>
						</button>
					</router-link>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { auth } from '@/service/AuthenticationService';
import UserService from '@/service/UserService';
export default {
	userService: null,
	props: {
		topbarMenuActive: Boolean,
		profileMode: String,
		horizontal: Boolean,
		activeTopbarItem: String,
	},
	data() {
		return {
			user: {},
		};
	},
	methods: {
		onTopbarMenuButtonClick(event) {
			this.$emit('topbar-menubutton-click', event);
		},
		logout() {
			auth.logout().then((res) => {
				if (res) {
					this.$router.push('/');
				}
			});
		},
		setDefaultUser() {
			this.user = {
				company: '',
				firstname: 'Mon Compte',
				lastname: '',
				type: 'User',
			};
		},
	},
	computed: {
		topbarItemsClass() {
			return [
				'topbar-items fadeInDown',
				{
					'topbar-items-visible': this.topbarMenuActive,
				},
			];
		},
	},
	created() {
		this.userService = new UserService();
	},
	mounted() {
		this.userService
			.getUser()
			.then((res) => {
				if (res.data) {
					this.user = {
						company: res.data.user.company_name,
						firstname: res.data.user.firstname,
						lastname: res.data.user.lastname,
						type: res.data.user.user_type ?? 'User',
					};
				} else {
					this.setDefaultUser();
				}
			})
			.catch((err) => {
				this.setDefaultUser();
				console.log(err);
			});
	},
};
</script>

<style>
.layout-wrapper .topbar .topbar-left {
	box-shadow: initial;
}
</style>