<template>
	<div :class="containerClass" @click="onDocumentClick">
		<div class="layout-wrapper-content">
			<AppTopBar
				:topbarMenuActive="topbarMenuActive"
				:profileMode="profileMode"
				:horizontal="true"
				:activeTopbarItem="activeTopbarItem"
				@menubutton-click="onMenuButtonClick"
				@topbar-menubutton-click="onTopbarMenuButtonClick"
				@topbar-item-click="onTopbarItemClick"
			/>
			<Toast />
			<ConfirmDialog />

			<div class="layout-main">
				<router-view />
			</div>
			<div class="layout-mask"></div>

			<AppFooter />
		</div>
	</div>
</template>

<script>
import AppTopBar from './AppTopbar.vue';
import AppFooter from './AppFooter.vue';
import EventBus from './event-bus';

export default {
	props: {
		theme: String,
		layoutColor: String,
		layoutScheme: String,
		darkMenu: Boolean,
	},
	data() {
		return {
			layoutMode: 'horizontal',
			profileMode: 'inline',
			overlayMenuActive: false,
			staticMenuDesktopInactive: false,
			staticMenuMobileActive: false,
			topbarMenuActive: false,
			activeTopbarItem: null,
			rotateMenuButton: false,
			menu: [],
			menuActive: false,
			profileExpanded: false,
			themeColors: [
				{ name: 'Blue', file: 'blue', color: '#0d6efd' },
				{ name: 'Purple', file: 'purple', color: '#6f42c1' },
			],
			layoutColors: [
				{ name: 'Blue', file: 'blue', color: '#0d6efd' },
				{ name: 'Purple', file: 'purple', color: '#6f42c1' },
			],
		};
	},
	watch: {
		$route() {
			this.menuActive = false;
			this.$toast.removeAllGroups();
		},
	},
	methods: {
		onDocumentClick() {
			if (!this.topbarItemClick) {
				this.activeTopbarItem = null;
				this.topbarMenuActive = false;
			}

			if (!this.menuClick) {
				this.menuActive = false;
				EventBus.emit('reset-active-index');

				this.hideOverlayMenu();
			}

			this.topbarItemClick = false;
			this.menuClick = false;
		},
		onProfileClick(event) {
			this.profileExpanded = !this.profileExpanded;
			EventBus.emit('reset-active-index');

			event.preventDefault();
		},
		onMenuClick() {
			this.menuClick = true;
		},
		onMenuItemClick(event) {
			if (!event.item.items) {
				EventBus.emit('reset-active-index');
				this.hideOverlayMenu();
			}
			if (!event.item.items) {
				this.menuActive = false;
			}
		},
		onRootMenuItemClick() {
			this.menuActive = !this.menuActive;
		},
		onMenuButtonClick(event) {
			this.menuClick = true;

			this.rotateMenuButton = !this.rotateMenuButton;
			this.topbarMenuActive = false;

			if (this.layoutMode === 'overlay') {
				this.overlayMenuActive = !this.overlayMenuActive;
			} else {
				if (this.isDesktop()) this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
				else this.staticMenuMobileActive = !this.staticMenuMobileActive;
			}

			event.preventDefault();
		},
		onTopbarMenuButtonClick(event) {
			this.topbarItemClick = true;
			this.topbarMenuActive = !this.topbarMenuActive;
			this.hideOverlayMenu();
			event.preventDefault();
		},
		onTopbarItemClick(event) {
			this.topbarItemClick = true;

			if (this.activeTopbarItem === event.item) this.activeTopbarItem = null;
			else this.activeTopbarItem = event.item;

			event.originalEvent.preventDefault();
		},
		hideOverlayMenu() {
			this.rotateMenuButton = false;
			this.overlayMenuActive = false;
			this.staticMenuMobileActive = false;
		},
		isDesktop() {
			return window.innerWidth > 1024;
		},
		isSlim() {
			return this.layoutMode === 'slim';
		},
	},
	computed: {
		containerClass() {
			return [
				'layout-wrapper',
				{
					'menu-layout-static': this.layoutMode !== 'overlay',
					'menu-layout-overlay': this.layoutMode === 'overlay',
					'layout-menu-overlay-active': this.overlayMenuActive,
					'menu-layout-slim': this.layoutMode === 'slim',
					'menu-layout-horizontal': this.layoutMode === 'horizontal',
					'layout-menu-static-inactive': this.staticMenuDesktopInactive,
					'layout-menu-static-active': this.staticMenuMobileActive,
					'p-input-filled': this.$primevue.config.inputStyle === 'filled',
					'p-ripple-disabled': this.$primevue.config.ripple === false,
				},
			];
		},
	},
	components: {
		AppTopBar: AppTopBar,
		AppFooter: AppFooter,
	},
};
</script>

<style lang="scss">
@import './App.scss';
</style>