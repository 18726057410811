<template>
    <!-- <div class="validate-body"> -->
    <div class="login-body">
        <Toast base-z-index.number="1500" />
        <div class="login-image"></div>
        <div class="card login-panel p-fluid">
            <div class="login-panel-content">
                <div class="grid">
                    <div v-if="isValidConfirmEmail && !isValidConfirmSms" class="col-12">
                        <div class="col-12">
                            <h5>{{ $t('VALIDATION.TITLE') }}</h5>
                            <InputText
                                id="phone"
                                v-model="user.confirm_sms_code"
                                type="text"
                                :placeholder="$t('VALIDATION.SMS.CODE')"
                                class="form-element mb-2"
                                :class="(!user.confirm_sms_code && submit) || invalidSmsCode ? 'p-invalid' : ''"
                                @keyup.enter="confirmSmsCode"
                            />
                            <small class="p-invalid" v-if="!user.confirm_sms_code && submit" style="color: red">{{ $t('REGISTER.ERROR.MISSING_FIELD') }}</small>
                            <p class="mt-2">{{ $t('VALIDATION.SMS.INFO') }}</p>
                        </div>
                        <div class="col-12 button-pane pt-2">
                            <Button type="submit" :label="$t('BUTTON.CONFIRM')" @click="confirmSmsCode" :disabled="!user.confirm_sms_code" />
                        </div>
                        <p>
                            <small
                                >{{ $t('VALIDATION.SMS.NO_RECEIVED') }} <a href="" @click.prevent="sendConfirmSmsCode">{{ $t('VALIDATION.SMS.RESEND') }}</a></small
                            >
                        </p>
                    </div>
                    <div v-else-if="isValidConfirmSms" class="col-12">
                        <h5>{{ $t('VALIDATION.SUCCESS.TITLE', { email: user.email }) }}</h5>
                        <p>{{ $t('VALIDATION.SUCCESS.REDIRECT') }}</p>
                        <p>
                            <small
                                >{{ $t('VALIDATION.SUCCESS.NO_REDIRECT') }} <a href="" @click.prevent="$router.push({ name: 'user-login' })">{{ $t('BUTTON.CLICK') }}</a></small
                            >
                        </p>
                    </div>
                    <div v-else-if="isError" class="col-12">
                        <h5>{{ $t('VALIDATION.ERROR.TITLE', { email: user.email }) }}</h5>
                        <p>{{ $t('VALIDATION.ERROR.MESSAGE') }}</p>
                        <p><i class="pi pi-phone"></i>&nbsp;{{ $t('PAGE.ROW.CONTACT.PHONE.TITLE') }}&nbsp;: {{ $t('PAGE.ROW.CONTACT.PHONE.TEXT') }}</p>
                        <p><i class="pi pi-at"></i>&nbsp;{{ $t('PAGE.ROW.CONTACT.EMAIL.TITLE') }}&nbsp;: {{ $t('PAGE.ROW.CONTACT.EMAIL.TEXT') }}</p>
                    </div>
                    <div v-else class="col-12">
                        <h5>{{ $t('VALIDATION.TITLE') }}</h5>
                        <ProgressBar mode="indeterminate" />
                        <p>{{ $t('VALIDATION.PROCESSING') }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import UserService from '@/service/UserService';
import { useRoute } from 'vue-router';

export default {
    name: 'validate-user',
    userService: null,
    route: null,
    data() {
        return {
            hash: '',
            isValidConfirmEmail: false,
            isValidConfirmSms: false,
            invalidSmsCode: false,
            isError: false,
            user: {},
            submit: false,
        };
    },
    computed: {},
    methods: {
        validate() {
            this.userService
                .confirmUser(this.hash)
                .then((res) => {
                    if (!res || res.status !== 200) {
                        this.isError = true;
                        this.$toast.add({ severity: 'error', summary: this.$t('TOAST.VALIDATION.ERROR.TITLE'), detail: this.$t('TOAST.VALIDATION.ERROR.MESSAGE') + res.data.error, life: 5000 });
                    } else {
                        this.user = res.data.user;
                        this.isValidConfirmEmail = true;
                    }
                })
                .catch((error) => {
                    this.isError = true;
                    this.$toast.add({ severity: 'error', summary: this.$t('TOAST.VALIDATION.ERROR.TITLE'), detail: this.$t('TOAST.VALIDATION.ERROR.MESSAGE') + " impossible d'envoyer la requête", life: 5000 });
                    console.log(error);
                });
        },
        confirmSmsCode() {
            this.submit = true;
            if (this.user.confirm_sms_code && this.user.id) {
                this.userService
                    .confirmSmsCode(this.user.confirm_sms_code, this.user.id)
                    .then((res) => {
                        if (!res || res.status !== 200) {
                            this.isError = true;
                            this.invalidSmsCode = true;
                            this.$toast.add({ severity: 'error', summary: this.$t('TOAST.VALIDATION.CONFIRM_CODE.ERROR.TITLE'), detail: this.$t('TOAST.VALIDATION.CONFIRM_CODE.ERROR.MESSAGE'), life: 5000 });
                        } else {
                            this.user = res.data.user;
                            this.isValidConfirmSms = true;
                            this.invalidSmsCode = false;
                            this.$toast.add({ severity: 'success', summary: this.$t('TOAST.VALIDATION.CONFIRM_CODE.SUCCESS.TITLE'), detail: this.$t('TOAST.VALIDATION.CONFIRM_CODE.SUCCESS.MESSAGE'), life: 3000 });
                            setTimeout(() => {
                                this.$router.push({ name: 'user-login' });
                            }, 5000);
                        }
                    })
                    .catch((error) => {
                        this.isError = true;
                        this.invalidSmsCode = true;
                        this.$toast.add({ severity: 'error', summary: this.$t('TOAST.VALIDATION.CONFIRM_CODE.ERROR.TITLE'), detail: this.$t('TOAST.VALIDATION.CONFIRM_CODE.ERROR.MESSAGE') + " impossible d'envoyer la requête", life: 5000 });
                        console.log(error);
                    });
            }
        },
        sendConfirmSmsCode() {
            if (this.user.id && this.hash) {
                this.userService
                    .sendConfirmSmsCode(this.hash)
                    .then((res) => {
                        if (!res || res.status !== 200) {
                            this.$toast.add({ severity: 'error', summary: this.$t('TOAST.VALIDATION.CONFIRM_CODE.SEND.ERROR.TITLE'), detail: this.$t('TOAST.VALIDATION.CONFIRM_CODE.SEND.ERROR.MESSAGE'), life: 5000 });
                        } else {
                            this.$toast.add({ severity: 'success', summary: this.$t('TOAST.VALIDATION.CONFIRM_CODE.SEND.SUCCESS.TITLE'), detail: this.$t('TOAST.VALIDATION.CONFIRM_CODE.SEND.SUCCESS.MESSAGE'), life: 3000 });
                        }
                    })
                    .catch((error) => {
                        this.$toast.add({ severity: 'error', summary: this.$t('TOAST.VALIDATION.CONFIRM_CODE.SEND.ERROR.TITLE'), detail: this.$t('TOAST.VALIDATION.CONFIRM_CODE.SEND.ERROR.MESSAGE') + " impossible d'envoyer la requête", life: 5000 });
                        console.log(error);
                    });
            }
        },
    },
    components: {},
    created() {
        this.userService = new UserService();
        this.route = useRoute();
    },
    mounted() {
        this.hash = this.route.query.k;
        this.validate();
    },
};
</script>

<style lang="scss" scoped></style>
