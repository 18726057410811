<template>
	<div class="row-pricing">
		<div class="price-type">
			<div class="price-header">
				<div class="grid">
					<div class="col-12 md:col-7">
						<h3>{{ $t('LANDING.PRICING.TITLE') }}</h3>
					</div>
					<div class="col-12 md:col-5">
						<span class="price-for">{{ $t('LANDING.PRICING.FRANCE.TITLE') }}</span>
						<p class="pricing">
							{{ $t('LANDING.PRICING.FRANCE.PRICE') }}&nbsp;<sup>{{ $t('LANDING.PRICING.FRANCE.CURRENCY') }}</sup>
						</p>
						<p class="payment">{{ $t('LANDING.PRICING.FRANCE.PAYMENT') }} / {{ $t('LANDING.PRICING.FRANCE.NOTICE') }}</p>
					</div>
				</div>
			</div>
			<div class="price-content">
				<div class="calculator">
					<h3>
						{{ $t('LANDING.PRICING.CALCULATOR.TITLE') }}
					</h3>
					<label for="estimate-quantity">{{ $t('LANDING.PRICING.CALCULATOR.QUANTITY') }}</label>
					<InputText id="estimate-quantity" type="number" min="1" max="9999999" v-model="calculatorQuantity" :class="'input-size-' + inputQuantitySize" />
					<div class="calculate">
						&nbsp;×&nbsp;{{ $t('LANDING.PRICING.FRANCE.PRICE') }}&nbsp;<sup>{{ $t('LANDING.PRICING.FRANCE.CURRENCY') }}</sup
						>&nbsp;=&nbsp;
					</div>
					<div class="result">
						<span>{{ calculatorResult }}</span>
					</div>
				</div>
			</div>
			<div class="price-footer">
				<!-- country selector -->
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'row-pricing',
	data() {
		return {
			calculatorQuantity: 1,
		};
	},
	computed: {
		calculatorResult() {
			let price = parseFloat(this.$t('LANDING.PRICING.FRANCE.PRICE').replace(',', '.'));
			let result = this.calculatorQuantity * price;
			return result.toFixed(3);
		},
		inputQuantitySize() {
			return this.calculatorQuantity.toString().length;
		},
	},
	methods: {},
	components: {},
	created() {},
	mounted() {
		this.calculatorQuantity = 1;
	},
	watch: {},
};
</script>

<style>
.row-pricing {
	margin: 20px;
	padding: 20px;
	background-color: #b1cd3f;
}

.row-pricing h2 {
	margin: 20px auto;
	font-family: Fredoka, 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	font-size: 2.2rem;
	text-align: center;
}

.row-pricing-content > div {
	padding: 20px 20px 0;
	text-align: center;
	font-family: Poppins, 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.row-pricing-content .row-pricing-header {
	font-size: 3rem;
	font-weight: 700;
	/* color: #faa633; */
	color: #ffffff;
}

.row-pricing-content .row-pricing-header .pi {
	font-size: 4rem;
	margin-right: 2rem;
}

.row-pricing-content .row-pricing-header span {
	display: inline-block;
	vertical-align: top;
}

.row-pricing-content .row-pricing-body {
	margin: 20px;
	font-size: 1.2rem;
	font-weight: 500;
}
</style>