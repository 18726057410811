<template>
	<div class="row-sms">
		<div class="grid row-sms-content">
			<div v-for="item of content" :key="item.icon" class="col-12 sm:col-6 lg:col-4">
				<div class="row-sms-header">
					<i :class="'pi pi-' + item.icon" />
					<span>{{ item.rate }}</span>
				</div>
				<div class="row-sms-body">
					<p>{{ item.text }}</p>
				</div>
				<div class="row-sms-footer"></div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'row-sms',
	data() {
		return {
			content: [],
		};
	},
	computed: {},
	methods: {},
	components: {},
	created() {},
	mounted() {
		this.content = [
			{
				icon: 'home',
				rate: '29%',
				text: this.$t('PAGE.ROW.SMS.SHOP'),
			},
			{
				icon: 'eye',
				rate: '90%',
				text: this.$t('PAGE.ROW.SMS.READ'),
			},
			{
				icon: 'mobile',
				rate: '95%',
				text: this.$t('PAGE.ROW.SMS.OPENED'),
			},
		];
	},
	watch: {},
};
</script>

<style>
.row-sms {
	margin: 20px;
	padding: 20px;
	background-color: #b1cd3f;
}

.row-sms h2 {
    margin: 20px auto;
    font-family: Fredoka, 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 2.2rem;
    text-align: center;
}

.row-sms-content > div {
	padding: 20px 20px 0;
	text-align: center;
    font-family: Poppins, 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.row-sms-content .row-sms-header {
	font-size: 3rem;
	font-weight: 700;
	/* color: #faa633; */
	color: #ffffff;
}

.row-sms-content .row-sms-header .pi {
	font-size: 4rem;
	margin-right: 2rem;
}

.row-sms-content .row-sms-header span {
	display: inline-block;
	vertical-align: top;
}

.row-sms-content .row-sms-body {
	margin: 20px;
	font-size: 1.2rem;
	font-weight: 500;
}
</style>