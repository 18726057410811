<template>
	<div class="wizard-body">
		<Toast base-z-index.number="1500" />
		<div class="wizard-image"></div>
		<div v-if="!success" class="wizard-wrapper">
			<div class="wizard-container">
				<div class="wizard-header">
					<div class="wizard-tabs-container">
						<div class="wizard-tab register-tab" :class="{ 'active-tab': activeTab !== null }" @click="clickNext('register')">
							<div class="tab-progressbar"></div>
							<div class="tab-header"></div>
						</div>
						<div class="wizard-tab tier-tab" :class="{ 'active-tab': activeTab === 'tier' || activeTab === 'payment' }" @click="clickNext('tier')">
							<div class="tab-progressbar"></div>
							<div class="tab-header"></div>
						</div>
						<div class="wizard-tab payment-tab" :class="{ 'active-tab': activeTab === 'payment' }" @click="clickNext('payment')">
							<div class="tab-progressbar"></div>
							<div class="tab-header"></div>
						</div>
					</div>
				</div>
				<div class="wizard-content register" :class="{ 'active-content': activeContent === 'register' }">
					<div class="content-header">
						<div class="grid">
							<div class="col-6 title">
								<h1>{{ $t('REGISTER.CREDENTIALS') }}</h1>
							</div>
						</div>
					</div>
					<div class="content">
						<div class="grid forms">
							<div class="col-12 md:col-12 p-0 m-0">
								<div class="col-12 md:col-6 pb-0">
									<label for="email" class="ml-1">{{ $t('REGISTER.EMAIL') }}<span>* </span></label>
									<small class="p-invalid" v-if="emailError.res && submitStepOne" style="color: red">{{ emailError.text }}</small>
									<InputText id="email" type="text" v-model="user.email_address" placeholder="Adresse email" class="form-element" :class="emailError.res && submitStepOne ? 'p-invalid' : ''" />
								</div>
							</div>
							<div class="col-12 md:col-6 pt-0">
								<label for="password" class="ml-1">{{ $t('REGISTER.PASSWORD') }}<span>* </span></label>
								<div class="from-element" style="margin-top: 10px">
									<PasswordMeter v-model="user.user_password" :pPassword="user.user_password" :pSubmitted="submitStepOne" :pError="passwordError.res" />
									<small class="p-invalid" v-if="passwordError.res && submitStepOne" style="color: red">{{ passwordError.text }}</small>
								</div>
							</div>
							<div class="col-12 md:col-6 pt-0">
								<label for="confirmpassword" class="ml-1">{{ $t('REGISTER.PASSWORD_CONFIRMATION') }}<span>* </span></label>
								<div class="form-element">
									<InputText id="confirmpassword" v-model="confirmPassword" type="password" :placeholder="$t('REGISTER.PASSWORD_CONFIRMATION')" :class="confirmPassError.res && submitStepOne ? 'p-invalid' : ''" />
									<small class="p-invalid" v-if="confirmPassError.res && submitStepOne" style="color: red">{{ confirmPassError.text }}</small>
								</div>
							</div>
						</div>
						<div class="grid button">
							<div class="col-12">
								<Button class="continue-button" :label="$t('BUTTON.CONTINUE').toUpperCase()" style="width: 100%" @click="clickNext('tier')"></Button>
							</div>
						</div>
					</div>
				</div>
				<div class="wizard-content tier" :class="{ 'active-content': activeContent === 'tier' }">
					<div class="content-header">
						<div class="grid">
							<div class="col-12 title">
								<h1>{{ $t('REGISTER.PERSONAL_INFORMATION') }}</h1>
							</div>
						</div>
					</div>
					<div class="content">
						<div class="grid forms">
							<div class="col-12 md:col-6">
								<label for="lastname" class="ml-1">{{ $t('REGISTER.LASTNAME') }}<span>* </span></label>
								<small class="p-invalid" v-if="!user.lastname && submitStepTwo" style="color: red">{{ $t('REGISTER.ERROR.MISSING_FIELD') }}</small>
								<InputText id="lastname" type="text" v-model="user.lastname" :placeholder="$t('REGISTER.LASTNAME')" class="form-element" :class="!user.lastname && submitStepTwo ? 'p-invalid' : ''" />
								<label for="firstname" class="ml-1">{{ $t('REGISTER.FIRSTNAME') }}<span>* </span></label>
								<small class="p-invalid" v-if="!user.firstname && submitStepTwo" style="color: red">{{ $t('REGISTER.ERROR.MISSING_FIELD') }}</small>
								<InputText id="firstname" v-model="user.firstname" type="text" :placeholder="$t('REGISTER.FIRSTNAME')" class="form-element" :class="!user.firstname && submitStepTwo ? 'p-invalid' : ''" />
								<label for="phone" class="ml-1">{{ $t('REGISTER.PHONE.LABEL') }}<span>* </span> </label>
								<small class="p-invalid" v-if="!user.mobile_phone_number && submitStepTwo" style="color: red">{{ $t('REGISTER.ERROR.MISSING_FIELD') }}</small>
								<InputText id="phone" v-model="user.mobile_phone_number" type="text" :placeholder="$t('REGISTER.PHONE.LABEL')" class="form-element" :class="!user.mobile_phone_number && submitStepTwo ? 'p-invalid' : 'mb-3'"/>
								<small class="text-white"><i class="pi pi-info-circle text-white" />&nbsp;{{ $t('REGISTER.PHONE.INFO') }}</small>
							</div>
							<div class="col-12 md:col-6">
								<label for="address" class="ml-1">{{ $t('REGISTER.ADDRESS') }}<span>* </span></label>
								<small class="p-invalid" v-if="!user.address_street && submitStepTwo" style="color: red">{{ $t('REGISTER.ERROR.MISSING_FIELD') }}</small>
								<InputText id="address" type="text" v-model="user.address_street" :placeholder="$t('REGISTER.ADDRESS')" class="form-element" :class="!user.address_street && submitStepTwo ? 'p-invalid' : ''" />
								<label for="postalcode" class="ml-1">{{ $t('REGISTER.POSTAL_CODE') }}<span>* </span></label>
								<small class="p-invalid" v-if="!user.address_postal_code && submitStepTwo" style="color: red">{{ $t('REGISTER.ERROR.MISSING_FIELD') }}</small>
								<InputText id="postalcode" v-model="user.address_postal_code" type="text" :placeholder="$t('REGISTER.POSTAL_CODE')" class="form-element" :class="!user.address_postal_code && submitStepTwo ? 'p-invalid' : ''" />
								<label for="city" class="ml-1">{{ $t('REGISTER.CITY') }}<span>* </span></label>
								<small class="p-invalid" v-if="!user.address_city && submitStepTwo" style="color: red">{{ $t('REGISTER.ERROR.MISSING_FIELD') }}</small>
								<InputText id="city" type="text" v-model="user.address_city" :placeholder="$t('REGISTER.CITY')" class="form-element" :class="!user.address_city && submitStepTwo ? 'p-invalid' : ''" />
							</div>
						</div>
						<div class="grid button">
							<div class="col-12">
								<Button class="continue-button" :label="$t('BUTTON.CONTINUE').toUpperCase()" style="width: 100%" @click="clickNext('payment')"></Button>
							</div>
						</div>
					</div>
				</div>
				<div class="wizard-content payment" :class="{ 'active-content': activeContent === 'payment' }">
					<div class="content-header">
						<div class="grid">
							<div class="col-12 title">
								<h1>{{ $t('REGISTER.COMPANY_INFORMATION') }}</h1>
							</div>
						</div>
					</div>
					<div class="content">
						<div class="grid forms">
							<div class="col-12 md:col-6">
								<label for="company" class="ml-1">{{ $t('REGISTER.COMPANY') }} ({{ $t('REGISTER.OPTIONAL').toLowerCase() }})</label>
								<InputText id="company" type="text" v-model="user.company_name" :placeholder="$t('REGISTER.COMPANY')" class="form-element" />
							</div>
							<div class="col-12 md:col-6">
								<label for="siret" class="ml-1">N° {{ $t('REGISTER.SIRET') }} ({{ $t('REGISTER.OPTIONAL').toLowerCase() }})</label>
								<InputText id="siret" type="text" v-model="user.company_siret" :placeholder="$t('REGISTER.SIRET')" class="form-element" />
							</div>
						</div>
						<div class="grid button">
							<div class="col-12">
								<Button class="continue-button" :label="$t('BUTTON.SIGNUP').toUpperCase()" style="width: 100%" @click="register"></Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="wizard-wrapper" v-if="success">
			<div class="wizard-container" style="white-space: pre-wrap; text-align: center; color: #ffffff; padding: 3rem 33%">
				<h2>{{ $t('REGISTER.SUCCESS.TITLE') }}</h2>
				<p class="mt-5 mb-2">{{ $t('REGISTER.SUCCESS.INFO') }}</p>
			</div>
		</div>
	</div>
</template>

<script>
import PasswordMeter from '@/components/PasswordMeter';
import UserService from '@/service/UserService';

export default {
	name: 'register-user',
	userService: null,
	data() {
		return {
			activeContent: 'register',
			activeTab: 'register',
			user: {},
			submitStepOne: false,
			submitStepTwo: false,
			confirmPassword: '',
			success: false,
		};
	},
	created() {
		this.userService = new UserService();
	},
	computed: {
		passwordError() {
			let passRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/g;
			if (this.user.user_password && !passRegex.test(this.user.user_password)) {
				return { res: true, text: this.$t('PASSWORD.ERROR.INVALID_PASSWORD') };
			} else if (!this.user.user_password) {
				return { res: true, text: this.$t('PASSWORD.ERROR.MISSING_PASSWORD') };
			}
			return { res: false, text: '' };
		},
		emailError() {
			let emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
			if (this.user.email_address && !emailRegex.test(this.user.email_address)) {
				return { res: true, text: this.$t('REGISTER.ERROR.INVALID_EMAIL') };
			} else if (!this.user.email_address) {
				return { res: true, text: this.$t('REGISTER.ERROR.MISSING_FIELD') };
			}
			return { res: false, text: '' };
		},
		confirmPassError() {
			if (!this.confirmPassword) {
				return { res: true, text: this.$t('REGISTER.ERROR.MISSING_FIELD') };
			} else if (this.user.user_password != this.confirmPassword) {
				return { res: true, text: this.$t('REGISTER.ERROR.INVALID_CONFIRM_PASS') };
			}
			return { res: false, text: '' };
		},
		informationError() {
			if (!this.user.lastname || !this.user.address_street || !this.user.address_city || !this.user.firstname || !this.user.address_postal_code) {
				return true;
			}
			return false;
		},
	},
	methods: {
		register() {
			this.userService
				.createUser(this.user)
				.then((res) => {
					if (res.status == 201) {
						this.$toast.add({ severity: 'success', summary: this.$t('TOAST.USER.REGISTER.SUCCESS.TITLE'), detail: this.$t('TOAST.USER.REGISTER.SUCCESS.MESSAGE'), life: 3000 });
							this.success = true;
					} else if (res.data) {
						console.log('Error ', res.status, res.statusText, '(' + res.data.error + ')');
						// TODO : check error and indicate the problem that must be corrected
						this.$toast.add({ severity: 'error', summary: this.$t('TOAST.USER.REGISTER.ERROR.TITLE'), detail: this.$t('TOAST.POWOW.RETRY.MESSAGE'), life: 10000 });
					} else {
						console.log(res, res.error);
						this.$toast.add({ severity: 'error', summary: this.$t('TOAST.API.TITLE'), detail: this.$t('TOAST.API.ERROR') + ' — ' + res.message, life: 10000 });
					}
				})
				.catch((error) => {
					console.log('ERROR', error, error.error);
					this.$toast.add({ severity: 'error', summary: this.$t('TOAST.API.TITLE'), detail: this.$t('TOAST.API.ERROR') + ' — ' + error.message, life: 10000 });
				});
		},
		clickNext(step) {
			if (step === 'register') {
				if (this.activeTab === 'register') {
					this.activeTab = 'register';
					this.activeContent = 'register';
				} else if (this.activeTab === 'tier') {
					setTimeout(() => {
						this.activeContent = 'register';
					}, 300);
					this.activeTab = 'register';
				} else {
					this.activeTab = 'tier';
					setTimeout(() => {
						this.activeTab = 'register';
					}, 300);
					setTimeout(() => {
						this.activeContent = 'register';
					}, 600);
				}
			}
			if (step === 'tier') {
				if (this.activeTab === 'register') {
					this.submitStepOne = true;
					if (this.emailError.res || this.passwordError.res || this.confirmPassError.res) {
						return;
					}
				}
				this.activeTab = 'tier';
				setTimeout(() => {
					this.activeContent = 'tier';
				}, 300);
			}

			if (step === 'payment') {
				if (this.activeTab === 'register') {
					this.submitStepOne = true;
					if (this.emailError.res || this.passwordError.res || this.confirmPassError.res) {
						return;
					}
				}
				if (this.activeTab === 'tier') {
					this.submitStepTwo = true;
					if (this.informationError) {
						return;
					}
				}
				if (this.activeTab === 'payment') {
					this.activeTab = 'payment';
					this.activeContent = 'payment';
				} else if (this.activeTab === 'tier') {
					setTimeout(() => {
						this.activeContent = 'payment';
					}, 300);
					this.activeTab = 'payment';
				} else {
					this.activeTab = 'tier';
					setTimeout(() => {
						this.activeTab = 'payment';
					}, 300);
					setTimeout(() => {
						this.activeContent = 'payment';
					}, 600);
				}
			}
		},
	},
	components: {
		PasswordMeter,
	},
};
</script>
<style lang="scss" scoped>
::v-deep(.p-password input) {
	width: 100%;
}
::v-deep(.p-password) {
	width: 100%;
}
</style>>
