import { readonly } from "vue";
import { axiosApi } from '@/axios.js';
import router from '@/router';

const headers = { "Content-Type": "application/json", "Accept": "application/json" };

function login(username, password) {
	const data = { email_address: username, user_password: password };

	return axiosApi.post("/connexion", data, { headers })
		.then(res => {
			localStorage.setItem('token', JSON.stringify(res.data.token));
			localStorage.setItem('pwkey', JSON.stringify(res.data.pwkey));
			return router.replace({ "name": "user-dashboard" });
		})
		.catch(error => { if (error) return false; });
}

function logout() {
	return axiosApi.post("/deconnexion", {}, { headers })
		.then(res => {
			localStorage.removeItem('token');
			localStorage.removeItem('pwkey');

			if (res) return true;
		})
		.catch(error => { if (error) return false; });
}

function isAdmin() {
	return axiosApi.get("/status")
		.then(res => {
			if (res.status == 200) {
				return res.data.user_status == 'Admin';
			}
			return false;
		})
		.catch(error => { if (error) return false; });
}

export const auth = readonly({
	login,
	logout,
	isAdmin
});