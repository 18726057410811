<template>
	<div class="login-body">
		<Toast base-z-index.number="1500" />
		<div class="login-image"></div>
		<div class="card login-panel p-fluid">
			<div class="login-panel-content">
				<div class="grid">
					<div class="col-12">
						<span>{{ $t('LOGIN.FORM.NOT_HAVE_ACCOUNT') }} </span><a href="" @click.prevent="$router.push({ name: 'user-sign-in' })">{{ $t('BUTTON.CLICK') }}</a>
					</div>
					<div class="col-12" style="text-align: left">
						<label class="login-label">{{ $t('LOGIN.FORM.USERNAME.LABEL') }}</label>
						<div class="login-input">
							<InputText v-model="username" @keyup.enter="login" :class="username === null ? 'p-invalid' : ''" aria-describedby="username-help" :placeholder="$t('LOGIN.FORM.USERNAME.PLACEHOLDER')" />
							<small id="username-help" v-show="username === null" class="p-error">{{ $t('LOGIN.FORM.USERNAME.ERROR') }}</small>
						</div>
					</div>
					<div class="col-12" style="text-align: left">
						<label class="login-label">{{ $t('LOGIN.FORM.PASSWORD.LABEL') }}</label>
						<div class="login-input">
							<InputText v-model="password" @keyup.enter="login" :class="password === null ? 'p-invalid' : ''" aria-describedby="password-help" type="password" :placeholder="$t('LOGIN.FORM.PASSWORD.PLACEHOLDER')" />
							<small id="password-help" v-show="password === null" class="p-error">{{ $t('LOGIN.FORM.PASSWORD.ERROR') }}</small>
							<div>
								<small class="p-mr-2">{{ $t('LOGIN.FORM.PASSWORD.FORGOTTEN.LABEL') }}</small
								><a href="" @click.prevent="showDialogForgotPass"
									><small>{{ $t('BUTTON.CLICK') }}</small></a
								>
							</div>
						</div>
					</div>
					<div class="col-12 md:col-12 button-pane">
						<Button type="submit" :label="$t('BUTTON.SIGNIN')" @click="login" />
					</div>
				</div>
			</div>
		</div>
		<Dialog :header="$t('MODAL.LOGIN.REMINDER.TITLE')" v-model:visible="displayForgotPass" :style="{ width: '330px' }" :modal="true">
			<div class="flex align-items-center justify-content-center">
				<InputText v-model="forgotUsername" :placeholder="$t('LOGIN.FORM.USERNAME.PLACEHOLDER')" />
			</div>
			<div class="flex align-items-center justify-content-center">
				<small id="username-help" v-show="forgotUsername === null" class="p-error">{{ $t('LOGIN.FORM.USERNAME.ERROR') }}</small>
			</div>
			<template #footer>
				<Button :label="$t('BUTTON.SEND')" icon="pi pi-check" class="p-button-success" @click="sendPassword" />
			</template>
		</Dialog>
	</div>
</template>

<script>
import { auth } from '@/service/AuthenticationService';

export default {
	data() {
		return {
			username: '',
			password: '',
			forgotUsername: '',
			displayForgotPass: false,
			checkRemindMe: false,
		};
	},
	methods: {
		showDialogForgotPass() {
			this.displayForgotPass = true;
			this.forgotUsername = this.username;
		},
		login() {
			this.username = this.username == '' ? null : this.username;
			this.password = this.password == '' ? null : this.password;
			if (this.username && this.password) {
				auth.login(this.username, this.password).then((res) => {
					if (!res) {
						this.$toast.add({ severity: 'error', summary: this.$t('TOAST.LOGIN.ERROR.TITLE'), detail: this.$t('TOAST.LOGIN.CREDENTIALS.ERROR.MESSAGE'), life: 3000 });
					}
				});
			}
		},
		logout() {
			auth.logout();
		},
		sendPassword() {
			this.forgotUsername = this.forgotUsername == '' ? null : this.forgotUsername;
			// if (this.forgotUsername) {
			// 	this.LoginService.sendPassword(this.forgotUsername)
			// 	.then(res => {
			// 		if (res.Success) {
			// 			this.$toast.add({severity:'success', summary: "Message", detail:'abcd'});
			// 			this.displayForgotPass = false;
			// 		}
			// 		else if(res.ErrorCode.includes(2)){
			// 			this.$toast.add({severity:'error', summary: 'abcd'});
			// 		}
			// 	})
			// }
		},
	},
};
</script>

<style scoped>
.text_over_image {
	position: relative;
	top: 50%;
	left: 50%;
	text-align: center;
	color: white;
	transform: translate(-50%, -50%);
	font-size: 4vw;
}
.login-panel {
	background-color: #3e3e3e;
}
.logo_over_image {
	position: fixed;
	top: 3%;
	left: 20px;
	width: 20%;
	max-width: 200px;
	height: auto;
}
.logo_footer {
	position: fixed;
	bottom: 5px;
	left: 20px;
	z-index: 99000;
	width: 20%;
	max-width: 160px;
	height: auto;
}
.text_footer {
	position: fixed;
	bottom: 1px;
	left: 50%;
	text-align: center;
	color: white;
	transform: translate(-50%, -5%);
	font-size: 0.7vw;
	z-index: 99000;
}
</style>
