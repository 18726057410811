<template>
	<div>
		<div class="landing-body">
			<div class="landing-wrapper">
				<div id="header">
					<Menu />
					<div class="header-content">
						<h1>{{ $t('LANDING.HEADER.CATCH') }}</h1>
						<h2 style="line-height: 1.5">{{ $t('LANDING.HEADER.LINE_1') }}</h2>
						<div>
							<button class="section-button" @click="openPageSms">{{ $t('BUTTON.MORE') }}</button>
						</div>
						<div class="motive">
							<p>{{ $t('LANDING.HEADER.LINE_2') }}</p>
							<p>{{ $t('LANDING.HEADER.LINE_3') }}</p>
						</div>
					</div>
				</div>

				<div id="features">
					<div>
						<h2 class="super">{{ $t('LANDING.FEATURES.SUPERTITLE') }}</h2>
						<h2>{{ $t('LANDING.FEATURES.TITLE') }}</h2>
						<div class="grid features-content">
							<div class="col-12 sm:col-6 lg:col-4">
								<div class="feature-header">
									<i class="pi pi-users" />
								</div>
								<h3>{{ $t('LANDING.FEATURES.MANAGE.TITLE') }}</h3>
								<p>{{ $t('LANDING.FEATURES.MANAGE.CONTENT') }}</p>
							</div>
							<div class="col-12 sm:col-6 lg:col-4">
								<div class="feature-header">
									<i class="pi pi-palette" />
								</div>
								<h3>{{ $t('LANDING.FEATURES.PERSONALIZE.TITLE') }}</h3>
								<p>{{ $t('LANDING.FEATURES.PERSONALIZE.CONTENT') }}</p>
							</div>
							<div class="col-12 sm:col-6 lg:col-4">
								<div class="feature-header">
									<i class="pi pi-thumbs-up" />
								</div>
								<h3>{{ $t('LANDING.FEATURES.TEST.TITLE') }}</h3>
								<p>{{ $t('LANDING.FEATURES.TEST.CONTENT') }}</p>
							</div>
							<div class="col-12 sm:col-6 lg:col-4">
								<div class="feature-header">
									<i class="pi pi-send" />
								</div>
								<h3>{{ $t('LANDING.FEATURES.SEND.TITLE') }}</h3>
								<p>{{ $t('LANDING.FEATURES.SEND.CONTENT') }}</p>
							</div>
							<div class="col-12 sm:col-6 lg:col-4">
								<div class="feature-header">
									<i class="pi pi-calendar" />
								</div>
								<h3>{{ $t('LANDING.FEATURES.PLAN.TITLE') }}</h3>
								<p>{{ $t('LANDING.FEATURES.PLAN.CONTENT') }}</p>
							</div>
							<div class="col-12 sm:col-6 lg:col-4">
								<div class="feature-header">
									<i class="pi pi-chart-bar" />
								</div>
								<h3>{{ $t('LANDING.FEATURES.STATS.TITLE') }}</h3>
								<p>{{ $t('LANDING.FEATURES.STATS.CONTENT') }}</p>
							</div>
						</div>
					</div>
				</div>

				<div id="pricing">
					<div>
						<div class="price-type">
							<div class="price-header">
								<div class="grid">
									<div class="col-12 md:col-7">
										<h3>{{ $t('LANDING.PRICING.TITLE') }}</h3>
									</div>
									<div class="col-12 md:col-5">
										<span class="price-for">{{ $t('LANDING.PRICING.FRANCE.TITLE') }}</span>
										<p class="pricing">
											{{ $t('LANDING.PRICING.FRANCE.PRICE') }}&nbsp;<sup>{{ $t('LANDING.PRICING.FRANCE.CURRENCY') }}</sup>
										</p>
										<p class="payment">{{ $t('LANDING.PRICING.FRANCE.PAYMENT') }} / {{ $t('LANDING.PRICING.FRANCE.NOTICE') }}</p>
									</div>
								</div>
							</div>
							<div class="price-content">
								<div class="calculator">
									<h3>
										{{ $t('LANDING.PRICING.CALCULATOR.TITLE') }}
									</h3>
									<label for="estimate-quantity">{{ $t('LANDING.PRICING.CALCULATOR.QUANTITY') }}</label>
									<InputText id="estimate-quantity" type="number" min="1" max="9999999" v-model="calculatorQuantity" :class="'input-size-' + inputQuantitySize" />
									<div class="calculate">
										&nbsp;×&nbsp;{{ $t('LANDING.PRICING.FRANCE.PRICE') }}&nbsp;<sup>{{ $t('LANDING.PRICING.FRANCE.CURRENCY') }}</sup
										>&nbsp;=&nbsp;
									</div>
									<div class="result">
										<span>{{ calculatorResult }}</span>
									</div>
								</div>
							</div>
							<div class="price-footer">
								<button class="section-button" @click="openPagePricing">{{ $t('LANDING.PRICING.MORE') }}</button>
							</div>
						</div>
					</div>
				</div>

				<div id="showcase-content-wrapper">
					<div class="showcase-content">
						<h2>{{ $t('LANDING.REASONS.TITLE') }}</h2>
						<ul>
							<li>{{ $t('LANDING.REASONS.LIST.0.TITLE') }}</li>
							<li>{{ $t('LANDING.REASONS.LIST.1.TITLE') }}</li>
							<li>
								{{ $t('LANDING.REASONS.LIST.2.TITLE') }} <span class="sub">— {{ $t('LANDING.REASONS.LIST.2.SUBTITLE') }}</span>
							</li>
							<li>{{ $t('LANDING.REASONS.LIST.3.TITLE') }}</li>
							<li>{{ $t('LANDING.REASONS.LIST.4.TITLE') }}</li>
						</ul>
					</div>
				</div>

				<div id="video">
					<div class="grid">
						<div class="col-0 md:col-2 lg:col-3">&nbsp;</div>
						<div class="col-12 md:col-8 lg:col-6 video-container">
							<iframe src="https://www.youtube.com/embed/tN6ZlXNR0gM" :title="$t('LANDING.VIDEO.TITLE')" class="video-player" allowfullscreen></iframe>
						</div>
						<div class="col-0 md:col-2 lg:col-3">&nbsp;</div>
					</div>
				</div>

				<Footer />
			</div>
		</div>
	</div>
</template>

<script>
import Footer from '@/components/public/Footer.vue';
import Menu from '@/components/public/Menu.vue';
export default {
	name: 'home-page',
	data() {
		return {
			calculatorQuantity: 1,
			reasons: [],
		};
	},
	computed: {
		calculatorResult() {
			let price = parseFloat(this.$t('LANDING.PRICING.FRANCE.PRICE').replace(',', '.'));
			let result = this.calculatorQuantity * price;
			return result.toFixed(3);
		},
		inputQuantitySize() {
			return this.calculatorQuantity.toString().length;
		},
	},
	methods: {
		openPageSms() {
			document.location.href = '#/p/sms';
		},
		openPagePricing() {
			document.location.href = '#/p/tarifs';
		},
	},
	components: {
		Footer,
		Menu,
	},
	created() {},
	mounted() {
		this.calculatorQuantity = 1;
	},
	watch: {},
};
</script>

<style lang="scss" scoped>
a {
	color: #000000;
	text-decoration: none;
}
</style>