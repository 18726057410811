import { axiosApi } from '@/axios.js';
// import axios from 'axios';

const headers = { "Content-Type": "application/json", "Accept": "application/json" };

export default class UserService {
    createUser(user) {
        return axiosApi.post("/inscription", user, { headers })
            .then(res => {
                return res;
            })
            .catch(error => {
                return this.handleError(error);
            });

    }

    getUser() {
        return axiosApi.get("/profil", {})
            .then(res => {
                return res;
            })
            .catch(error => {
                return this.handleError(error);
            });
    }

    updateUser(user) {
        return axiosApi.post("/modification", user)
            .then(res => {
                return res;
            })
            .catch(error => {
                return this.handleError(error);
            });
    }

    confirmUser(keyhash) {
        return axiosApi.post("/validation/email", { 'keyhash': keyhash })
            .then(res => {
                return res;
            })
            .catch(error => {
                return this.handleError(error);
            });
    }

    confirmSmsCode(confirmCode, userId = null) {
        return axiosApi.post("/validation/sms", { 'confirmCode': confirmCode, 'id': userId })
            .then(res => {
                return res;
            })
            .catch(error => {
                return this.handleError(error);
            });
    }

    sendConfirmSmsCode(keyhash) {
        return axiosApi.post("/validation/sms/envoyer", { 'keyhash': keyhash })
            .then(res => {
                return res;
            })
            .catch(error => {
                return this.handleError(error);
            });
    }

    handleError(error) {
        console.log(error);
        if (error.response.status && error.response.status == 401) {
            // Disconnected
            localStorage.removeItem('token');
            localStorage.removeItem('pwkey');
            document.location.href = '#/connexion';
        }
        return error.response;
    }
}
