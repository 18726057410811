import { createRouter, createWebHashHistory } from 'vue-router';
import UserService from '@/service/UserService';
import { auth } from './service/AuthenticationService';

function guardUser(to, from, next) {
	let userService = new UserService();
	userService.getUser()
		.then(() => {
			next();
		})
		.catch(err => {
			console.log(err);
		});
}

function guardConnectedUser(to, from, next) {
	if (localStorage.getItem("token")) {
		next('/accueil');
	} else {
		next();
	}
}

function guardAdmin(to, from, next) {
	auth.isAdmin()
		.then((res) => {
			if (res) {
				next();
			} else {
				next('/accueil');
			}
		})
		.catch(err => {
			console.log(err);
			next('/accueil');
		});
}

const routes = [
	{
		path: '/',
		name: 'home',
		component: () => import('@/pages/Home.vue'),
	},
	{
		path: '/p/:pre/:slug',
		name: 'blog-page',
		component: () => import('@/pages/PublicPage.vue'),
		meta: {}
	},
	{
		path: '/p/:slug',
		name: 'public-page',
		component: () => import('@/pages/PublicPage.vue'),
	},
	{
		path: '/inscription',
		name: 'registration',
		component: () => import('@/pages/Register.vue'),
		beforeEnter: guardConnectedUser
	},
	{
		path: '/connexion',
		name: 'user-login',
		component: () => import('@/pages/Login.vue'),
	},
	{
		path: '/inscription',
		name: 'user-sign-in',
		component: () => import('@/pages/Register.vue'),
	},
	{
		path: '/confirmation',
		name: 'user-validate',
		component: () => import('@/pages/Validation.vue'),
	},
	{
		path: '/admin/login',
		name: 'admin-login',
		component: () => import('@/pages/Login.vue'),
	},
	{
		path: '/accueil',
		name: 'user-dashboard',
		component: () => import('@/pages/user/Dashboard.vue'),
		beforeEnter: guardUser
	},
	{
		path: '/profil',
		name: 'user-profile',
		component: () => import('@/pages/user/Account.vue'),
		beforeEnter: guardUser
	},
	{
		path: '/listes',
		name: 'lists',
		component: () => import('@/pages/user/Lists.vue'),
		beforeEnter: guardUser
	},
	{
		path: '/liste/creer',
		name: 'list-creation',
		component: () => import('@/pages/user/ListCreate.vue'),
		beforeEnter: guardUser
	},
	{
		path: '/liste/modifier/:listId',
		name: 'list-edition',
		component: () => import('@/pages/user/ListEdit.vue'),
		beforeEnter: guardUser
	},
	{
		path: '/liste/visualiser/:listId',
		name: 'list-view',
		component: () => import('@/pages/user/ListView.vue'),
		beforeEnter: guardUser
	},
	{
		path: '/location',
		name: 'list-rent',
		component: () => import('@/pages/user/ListRent.vue'),
		beforeEnter: guardUser
	},
	{
		path: '/campagnes',
		name: 'campaigns',
		component: () => import('@/pages/user/Campaigns.vue'),
		beforeEnter: guardUser
	},
	{
		path: '/campagne/creer',
		name: 'campaign-creation',
		component: () => import('@/pages/user/CampaignCreate.vue'),
		beforeEnter: guardUser
	},
	{
		path: '/campagne/envoyer/:campaignId',
		name: 'campaign-sending',
		component: () => import('@/pages/user/CampaignSend.vue'),
		beforeEnter: guardUser
	},
	{
		path: '/campagne/modifier/:campaignId',
		name: 'campaign-edition',
		component: () => import('@/pages/user/CampaignEdit.vue'),
		beforeEnter: guardUser
	},
	{
		path: '/campagne/paiement/:campaignId',
		name: 'campaign-payment',
		component: () => import('@/pages/user/CampaignPostPayment.vue'),
		beforeEnter: guardUser
	},
	{
		path: '/campagne/stats/:campaignId',
		name: 'campaign-stats',
		component: () => import('@/pages/user/CampaignStats.vue'),
		beforeEnter: guardUser
	},
	{
		path: '/factures',
		name: 'invoices',
		component: () => import('@/pages/user/Invoices.vue'),
		beforeEnter: guardUser
	},
	{
		path: '/facture/visualiser/:campaignId',
		name: 'invoice-view',
		component: () => import('@/pages/user/InvoiceView.vue'),
		beforeEnter: guardUser
	},
	{
		path: '/location',
		name: 'rental',
		component: () => import('@/components/EmptyView.vue'),
		beforeEnter: guardUser
	},
	{
		path: '/admin',
		component: () => import('@/components/EmptyView.vue'),
		// beforeEnter: guardUser,
		beforeEnter: guardAdmin,
		redirect: '/admin/dashboard',
		children: [
			{
				path: '/admin/dashboard',
				name: 'admin-dashboard',
				component: () => import('@/pages/admin/Dashboard.vue'),
				beforeEnter: guardAdmin,
				meta: {}
			},
			{
				path: '/admin/cms',
				name: 'adminCms',
				component: () => import('@/pages/admin/Pages.vue'),
				beforeEnter: guardAdmin,
				meta: {}
			},
			{
				path: '/admin/cms/new',
				name: 'adminCmsNew',
				component: () => import('@/pages/admin/PageEdit.vue'),
				beforeEnter: guardAdmin,
				meta: {
					state: "create",
				}
			},
			{
				path: '/admin/cms/:slug/edit',
				name: 'adminCmsEdit',
				component: () => import('@/pages/admin/PageEdit.vue'),
				beforeEnter: guardAdmin,
				meta: {
					state: "edit",
				}
			},
			{
				path: '/admin/users',
				name: 'adminUsers',
				component: () => import('@/pages/admin/Users.vue'),
				beforeEnter: guardAdmin,
				meta: {}
			},
			{
				path: '/admin/users/new',
				name: 'adminUserNew',
				component: () => import('@/pages/admin/UserEdit.vue'),
				beforeEnter: guardAdmin,
				meta: {
					state: "create",
				}
			},
			{
				path: '/admin/users/:id/edit',
				name: 'adminUserEdit',
				component: () => import('@/pages/admin/UserEdit.vue'),
				beforeEnter: guardAdmin,
				meta: {
					state: "edit",
				}
			},
			{
				path: '/admin/stats',
				name: 'admin-stats',
				component: () => import('@/pages/admin/Statistics.vue'),
				beforeEnter: guardAdmin,
			},
			{
				path: '/admin/payments',
				name: 'admin-payments',
				component: () => import('@/pages/admin/Invoices.vue'),
				beforeEnter: guardAdmin,
			},
			{
				path: '/admin/campaigns',
				name: 'admin-campaigns',
				component: () => import('@/pages/admin/Campaigns.vue'),
				beforeEnter: guardAdmin,
			},
			{
				path: '/admin/lists',
				name: 'admin-lists',
				component: () => import('@/pages/admin/Lists.vue'),
				beforeEnter: guardAdmin,
			},
			{
				path: '/admin/blacklists',
				name: 'admin-blacklists',
				component: () => import('@/pages/admin/Blacklists.vue'),
				beforeEnter: guardAdmin,
				meta: {}
			},
		]
	}
];

const router = createRouter({
	history: createWebHashHistory(),
	routes,
	scrollBehavior() {
		return { left: 0, top: 0 };
	}
});

export default router;