<template>
    <div>
        <Password v-model="password" toggleMask :class="passwordError && submitted ? 'p-invalid' : ''" autocomplete="new-password"
            :weakLabel="'Faible'" :mediumLabel="$t('PASSWORD.MEDIUM')" :strongLabel="$t('PASSWORD.STRONG')" :promptLabel="$t('PASSWORD.ENTER_PASSWORD')" 
			:placeholder="$t('PASSWORD.ENTER_PASSWORD')" @input="$emit('update:modelValue', password)">
            <template #footer="sp">
                {{sp.level}}
                <Divider />
                <p class="p-mt-2">{{ $t('PASSWORD.SUGGESTIONS') }}</p>
                <ul class="p-pl-2 p-ml-2 p-mt-0" style="line-height: 1.5;list-style-type: none;">
                    <li><i :class="passOneLower.icon" :style="passOneLower.style"></i> {{ $t('PASSWORD.ONE_LOWERCASE') }}</li>
                    <li><i :class="passOneUpper.icon" :style="passOneUpper.style"></i> {{ $t('PASSWORD.ONE_UPPERCASE') }}</li>
                    <li><i :class="passOneNum.icon" :style="passOneNum.style"></i> {{ $t('PASSWORD.ONE_NUMERIC') }}</li>
                    <li><i :class="passMinChar.icon" :style="passMinChar.style"></i> {{ $t('PASSWORD.LEAST_EIGHT_CHAR') }}</li>
                </ul>
            </template>
        </Password>
    </div>
</template>
<script>
export default {
    name: "PasswordMeter",
    props: {
        pPassword: {
            type: String,
            required: true
        },
        pSubmitted: {
            type: Boolean,
            required: false
        },
        pError: {
            type: Boolean,
            required: false
        }
    },
    emits: ["update:modelValue"],
    data() {
        return {
            password: "",
            submitted: false,
            passwordError: false
        }
    },
    mounted() {
        this.password = this.pPassword || "";
        this.submitted = this.pSubmitted || false;
        this.passwordError = this.pError;
    },
    computed:{
        passOneLower() {
            if (/(?=.*[a-z])/g.test(this.password) && this.password) {
                return {icon: "pi pi-check", style: "color:green"};
            }
            return {icon: "pi pi-times", style: "color:red"};
        },
        passOneUpper() {
            if (/(?=.*[A-Z])/g.test(this.password) && this.password) {
                return {icon: "pi pi-check", style: "color:green"};
            }
            return {icon: "pi pi-times", style: "color:red"};
        },
        passOneNum() {
            if (/(?=.*\d)/g.test(this.password) && this.password) {
                return {icon: "pi pi-check", style: "color:green"};
            }
            return {icon: "pi pi-times", style: "color:red"};
        },
        passMinChar() {
            if (/^.{8,}$/g.test(this.password) && this.password) {
                return {icon: "pi pi-check", style: "color:green"};
            }
            return {icon: "pi pi-times", style: "color:red"};
        }
    },
    watch: {
        pError() {
            this.passwordError = this.pError;
        },
        pSubmitted() {
            this.submitted = this.pSubmitted;
        }
    }
}
</script>
<style lang="scss" scoped>
</style>