<template>
	<div class="layout-footer">
		<span class="footer-text-left">
			<img alt="Logo" src="layout/images/logo_h_grey.png" />
		</span>
		<span class="footer-text-right">
			<button class="p-link" @click="openWebsite"><i class="pi pi-globe"></i></button>
			<button class="p-link" @click="openTwitter"><i class="pi pi-twitter"></i></button>
			<button class="p-link" @click="openLinkedIn"><i class="pi pi-linkedin"></i></button>
			<button class="p-link" @click="openYouTube"><i class="pi pi-youtube"></i></button>
		</span>
	</div>
</template>

<script>
export default {
	name: 'AppFooter',
	methods: {
		openWebsite() {
			window.open('https://smsyou.fr');
		},
		openTwitter() {
			window.open('https://twitter.com/SmsYou_france');
		},
		openLinkedIn() {
			window.open('https://www.linkedin.com/company/smsyou/');
		},
		openYouTube() {
			window.open('https://www.youtube.com/channel/UCOw7AgmayXFrtF5hn4h5K2Q');
		},
	},
};
</script>

<style scoped>
</style>
